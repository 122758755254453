:root {
  --white-color: #ffff;
  --backgroundBody-color: #f2f1f1;
  --background-gray-md-color: #c7c7c7;
  --background-gray-light-color: #f0f0f1;
  --background-socket: 201,201,201;
  --black-main-color: 32,32,32;
  --primary-color: 229,81,183;
  --primary-medium-color:236,175,212;
  --primary-light-color:236,175,212;
  --gray-md-color: 153,153,153;
  --gray-dark-color: 51,51,51;
  --gray-light-color: 255,229,254;
  --gray-blur-color:255,255,255;
  --menu-height:12rem;
  --phone-menu-height:16rem;
  --gap-small:2rem;
  --gap-md:4rem;
  --gap-lg:6rem;
  --nav-font-light:1.3rem;
  --nav-font-md:1.5rem ;
  --nav-font-lg:2rem ;
  --font-extra-lg: 3.5rem;
  --review-height:60rem; }
  @media (max-width: 37.5em) {
    :root {
      --nav-font-light:2rem;
      --nav-font-md:2.5rem ; } }

.loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  filter: hue-rotate(0deg);
  background: rgba(var(--primary-color), 0.4);
  backdrop-filter: blur(1rem);
  z-index: 1000000000;
  transition: all .2s;
  /*   animation:hue 10000ms infinite linear; */ }
  .loader__header {
    font-family: inherit;
    color: var(--white-color);
    text-shadow: 0 2px 3px rgba(var(--black-main-color), 0.4);
    position: absolute;
    top: calc(50% - 12rem);
    left: calc(50% - 11rem);
    z-index: 100000000000;
    margin-bottom: 1rem; }

.loader .b1 {
  left: 42%; }

.loader .b2 {
  left: 50%;
  animation-delay: 100ms; }

.loader .b3 {
  left: 58%;
  animation-delay: 200ms; }

.loader .b1,
.loader .b2,
.loader .b3 {
  width: 10px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 50%;
  transform: rotate(0);
  animation-name: spinify;
  animation-duration: 1600ms;
  animation-iteration-count: infinite; }

@keyframes spinify {
  0% {
    transform: translate(0px, 0px); }
  33% {
    transform: translate(0px, 24px);
    border-radius: 100%;
    width: 10px;
    height: 10px; }
  66% {
    transform: translate(0px, -16px); }
  88% {
    transform: translate(0px, 4px); }
  100% {
    transform: translate(0px, 0px); } }

@keyframes hue {
  0% {
    filter: hue-rotate(0deg); }
  100% {
    filter: hue-rotate(360deg); } }

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    opacity: 0.9;
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translate(0px); } }

@keyframes moveInButton {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0px); } }

* {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
  direction: inherit; }

::selection {
  background: rgba(var(--primary-color), 1);
  color: var(--white-color); }

html {
  scroll-behavior: smooth;
  scrollbar-width: thin;
  /* "auto" or "thin" */
  scrollbar-color: #eb51b7 white;
  font-size: 10px; }
  @media (min-width: 122.5em) {
    html {
      font-size: 75%; } }
  @media (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media (max-width: 56.25em) {
    html {
      font-size: 50%; } }
  @media (max-width: 37.5em) {
    html {
      font-size: 27%; } }
  @media (max-width: 20em) {
    html {
      font-size: 19%; } }

body {
  box-sizing: border-box;
  direction: rtl;
  height: auto;
  background-color: var(--backgroundBody-color);
  width: 100vw;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden; }
  body::after {
    content: '';
    display: table;
    clear: both; }

main {
  min-height: 100vh;
  margin-bottom: 10rem; }

.active {
  opacity: 1 !important;
  visibility: visible !important; }

.active-dot {
  color: rgba(var(--primary-color), 1); }

.active-category {
  opacity: 1 !important;
  visibility: visible !important; }

.active-category > .category__item-about {
  opacity: 1 !important;
  visibility: visible !important; }

.active-category .category__item-link {
  height: 16rem;
  width: inherit;
  transition: all .3s;
  z-index: 1; }

.active-category .category__item-img {
  transform: scale(2) !important;
  transition: all .3s; }

.prev-category {
  opacity: 1 !important;
  visibility: visible !important;
  transition: all .3s; }

.prev-category .category__item-link {
  width: 14rem;
  height: 14rem;
  z-index: 1;
  transform: translate(-224%, 9%);
  transition: all .3s; }
  @media (max-width: 75em) {
    .prev-category .category__item-link {
      transform: translate(-160%, 9%); } }
  @media (max-width: 56.25em) {
    .prev-category .category__item-link {
      transform: translate(-120%, 10%); } }

input, a {
  -webkit-appearance: none;
  appearance: none; }

.prev-category .category__item-img {
  transition: all .3s;
  transform: translate(3%, 10%); }

.next-category {
  opacity: 1 !important;
  visibility: visible !important;
  transition: all .3s; }

.next-category .category__item-link {
  width: 14rem;
  height: 14rem;
  z-index: 1;
  transform: translate(246%, 10%);
  transition: all .3s; }
  @media (max-width: 75em) {
    .next-category .category__item-link {
      transform: translate(160%, 10%); } }
  @media (max-width: 56.25em) {
    .next-category .category__item-link {
      transform: translate(120%, 10%); } }

.next-category .category__item-img {
  transition: all .3s;
  transform: translate(11%, 5%); }

/* width */
::-webkit-scrollbar {
  width: 9px;
  border-radius: 1rem; }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 1px 4px rgba(var(--black-main-color), 0.3);
  border-radius: 1rem; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(var(--primary-light-color), 1);
  border-radius: 1rem; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--primary-color), 0.6); }

::-webkit-scrollbar-thumb:active {
  background: rgba(var(--primary-color), 1); }

.active-shop {
  color: rgba(var(--primary-color), 1); }

.fixed {
  position: fixed !important;
  top: var(--menu-height);
  right: auto; }

@font-face {
  font-family: Vazir;
  font-style: normal;
  src: url("../../css/fonts/vazir/Vazir-Regular-FD.ttf");
  src: url("../../css/fonts/vazir/Vazir-Regular-FD.eot");
  src: url("../../css/fonts/vazir/Vazir-Regular-FD.woff");
  src: url("../../css/fonts/vazir/Vazir-Regular-FD.woff2"); }

@font-face {
  font-family: Vazir-thin;
  font-style: normal;
  src: url("../../css/fonts/vazir/Vazir-Thin-FD.ttf");
  src: url("../../css/fonts/vazir/Vazir-Thin-FD.eot");
  src: url("../../css/fonts/vazir/Vazir-Thin-FD.woff");
  src: url("../../css/fonts/vazir/Vazir-Thin-FD.woff2"); }

@font-face {
  font-family: vazir-bold;
  font-style: normal;
  src: url("../../css/fonts/vazir/Vazir-Bold-FD.ttf");
  src: url("../../css/fonts/vazir/Vazir-Bold-FD.eot");
  src: url("../../css/fonts/vazir/Vazir-Bold-FD.woff");
  src: url("../../css/fonts/vazir/Vazir-Bold-FD.woff2"); }

body {
  font-family: Vazir;
  font-weight: 400;
  font-size: 1.6rem;
  color: #202020;
  line-height: 1.7; }

a {
  text-decoration: none;
  color: rgba(var(--black-main-color), 1); }

ul {
  list-style: none; }

.u-text-center {
  text-align: center; }

.u-mg-btm-sml {
  margin-bottom: 1.5rem !important; }

.u-mg-btm-md {
  margin-bottom: 4rem !important; }

.u-mg-btm-big {
  margin-bottom: 8rem !important; }

.u-mg-top-big {
  margin-top: 7rem !important; }

.u-mg-top-huge {
  margin-top: 10rem !important; }

.btn, .btn:link, .btn:visited {
  font-family: inherit;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  padding: 1.5rem 4rem;
  border-radius: 10rem;
  transition: all 0.2s ease-in;
  position: relative;
  animation: moveInButton 1s ease-out 0.75s;
  animation-fill-mode: backwards;
  border: none;
  cursor: pointer; }

.btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: -1;
  border-radius: 10rem;
  transition: all .2s; }

.btn--white {
  background-color: #fff;
  color: #202020; }
  .btn--white:hover {
    transform: translateY(-0.1rem);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); }
  .btn--white:active {
    transform: translateY(0rem);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5); }
  .btn--white::after {
    background-color: #fff; }

.btn:hover::after {
  transform: scaleX(1.5) scaleY(1.7);
  opacity: 0; }

.btn--custom {
  background-color: #e551b7;
  color: #fff;
  font-size: 1.8rem;
  line-height: 1.5rem;
  width: 20rem;
  height: 4.5rem; }
  @media (max-width: 56.25em) {
    .btn--custom {
      font-size: 1.5rem; } }
  .btn--custom-back {
    background-color: #006b99;
    color: #fff !important;
    font-size: 1.8rem;
    line-height: 1.5rem;
    width: 95%;
    height: 4.5rem; }
    @media (max-width: 37.5em) {
      .btn--custom-back {
        width: 95%;
        height: 8.5rem;
        font-size: 4.5rem;
        margin-top: 4rem; } }
  @media (max-width: 37.5em) {
    .btn--custom {
      width: 95%;
      height: 8.5rem;
      font-size: 4.5rem; } }
  @media (max-width: 37.5em) {
    .btn--custom-forgot {
      width: 40rem;
      margin-right: -5rem;
      font-size: 4rem; } }

.btn-text:link, .btn-text:visited {
  text-decoration: none;
  color: #e551b7;
  display: inline-block;
  border-bottom: 1px solid #e551b7;
  padding: 3px;
  font-size: 1.6rem; }

.twoSideContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 53%;
  width: 80rem;
  height: 93%;
  perspective: 150rem;
  -moz-perspective: 150rem; }
  @media (max-width: 37.5em) {
    .twoSideContainer {
      width: 89%;
      height: 115rem;
      perspective: 300rem;
      -moz-perspective: 300rem; } }

.headerFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto; }

.form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: all .8s ease;
  border-radius: 1.3rem; }
  .form-front {
    background-image: linear-gradient(77deg, rgba(229, 81, 183, 0.6) 0%, rgba(229, 81, 183, 0.6) 50%, rgba(255, 255, 255, 0.91) 50%), url("/img/slaid4-2-1 1.jpg");
    background-size: cover;
    filter: drop-shadow(0px 15px 26px rgba(0, 0, 0, 0.25)); }
    @media (max-width: 37.5em) {
      .form-front {
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.6) 100%), url("/img/slaid4-2-1 1.jpg");
        background-size: cover;
        box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.6); } }
    .form-front:target {
      transform: translate(-50%, -50%) rotateY(0deg); }
  .form__container {
    height: 100%;
    padding-right: 3rem; }
  .form__header {
    position: relative;
    width: 39%;
    font-size: 2.3rem;
    line-height: 6rem;
    display: inline-block;
    color: #202020;
    text-shadow: 0px 1px 4px rgba(32, 32, 32, 0.24);
    margin-bottom: 1rem; }
    @media (max-width: 37.5em) {
      .form__header {
        text-align: right;
        font-size: 5.3rem;
        padding-right: 6rem;
        line-height: 14rem;
        flex: 0 0 50%; } }
  .form__group {
    width: 40%;
    height: 5rem;
    position: relative;
    margin-bottom: 0.6rem; }
    @media (max-width: 37.5em) {
      .form__group {
        width: 90%;
        margin: 3rem auto;
        height: 10rem; } }
    .form__group:not(:last-child) {
      margin-top: .1rem; }
    .form__group:last-child {
      margin-top: .5rem; }
  .form__input {
    border: none;
    padding: 1.5rem 2rem;
    font-family: inherit;
    width: 98%;
    height: 3.5rem;
    background-color: rgba(255, 255, 255, 0.5);
    border-bottom: 3px solid transparent;
    transition: all .3s;
    border-radius: 3rem;
    -webkit-appearance: none;
    -webkit-box-shadow: -1px 1px 0.4rem rgba(var(--black-main-color), 0.3);
    box-shadow: -1px 1px 0.4rem rgba(var(--black-main-color), 0.3); }
    @media (max-width: 37.5em) {
      .form__input {
        height: 80%;
        font-size: 3rem; } }
    .form__input:focus {
      outline: none;
      border-bottom: 3px solid #55c57a;
      box-shadow: inset -1px 1px 0.4rem rgba(var(--black-main-color), 0.3); }
    .form__input:focus:invalid {
      border-bottom: 3px solid #ff7730; }
  .form__input:placeholder-shown ~ .form__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-3rem); }
  .form__label {
    display: block;
    font-size: 1.3rem;
    transition: all .4s;
    margin-right: 2rem;
    margin-top: 0.7rem; }
  .form__icon {
    cursor: pointer;
    opacity: 1;
    position: absolute;
    top: 1rem;
    left: 2rem;
    width: 2rem;
    height: 2rem; }
    @media (max-width: 37.5em) {
      .form__icon {
        top: 2.5rem;
        left: 4rem;
        font-size: 3.5rem;
        width: 4rem;
        height: 4rem; } }
    .form__icon-hide {
      position: absolute; }
    .form__icon-show {
      position: absolute;
      opacity: 0;
      visibility: hidden; }
  .form__radio-group {
    display: inline-block;
    width: 24%;
    padding-right: 4rem;
    transform: translateY(0rem); }
    @media (max-width: 37.5em) {
      .form__radio-group {
        transform: translateY(-2rem);
        display: inline-block;
        width: 29%;
        padding-right: 12rem;
        font-size: 3rem; } }
  .form__radio-button {
    position: absolute;
    right: 0;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 3.5px solid #e551b7; }
    .form__radio-button::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      opacity: 0;
      visibility: hidden;
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: #e551b7;
      transition: all .2s; }
    @media (max-width: 37.5em) {
      .form__radio-button {
        right: 30px;
        width: 4rem;
        height: 4rem; }
        .form__radio-button::after {
          width: 2rem;
          height: 2rem; } }
  .form__radio-input:checked ~ .form__radio-label .form__radio-button::after {
    opacity: 1;
    visibility: visible; }
  .form__checkbox-group {
    padding-right: 1rem;
    margin-top: .9rem; }
    @media (max-width: 37.5em) {
      .form__checkbox-group {
        margin-top: -1.1rem;
        margin-bottom: 2rem; } }
  .form__checkbox-input {
    padding-right: 2.5rem; }
  .form__checkbox-label {
    position: relative;
    padding-right: 2.5rem;
    font-size: 1.4rem; }
    @media (max-width: 37.5em) {
      .form__checkbox-label {
        padding-right: 11.5rem;
        font-size: 3.4rem; } }
  .form__checkbox-button {
    cursor: pointer;
    position: absolute;
    right: -1rem;
    top: .1rem;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border-radius: 5px;
    border: 3.5px solid #e551b7; }
    @media (max-width: 37.5em) {
      .form__checkbox-button {
        right: 5rem;
        top: 1.1rem;
        width: 4rem;
        height: 4rem; } }
  .form__checkbox-check {
    color: #e551b7;
    font-size: 2rem;
    position: absolute;
    top: -.5rem;
    right: -.5rem;
    transition: all .3s;
    opacity: 0;
    visibility: hidden; }
  .form__checkbox-input:checked ~ .form__checkbox-label .form__checkbox-button .form__checkbox-check {
    opacity: 1;
    visibility: visible;
    font-size: 2rem; }
    @media (max-width: 37.5em) {
      .form__checkbox-input:checked ~ .form__checkbox-label .form__checkbox-button .form__checkbox-check {
        font-size: 3rem; } }
  .form__checkbox-small {
    margin: -.5rem;
    font-size: 1.2rem; }
    @media (max-width: 37.5em) {
      .form__checkbox-small {
        margin: 9.5rem;
        font-size: 2.2rem; } }
    .form__checkbox-small-btn {
      color: #e551b7; }
  .form .btn-simple:link, .form .btn-simple:visited {
    position: absolute;
    left: 0;
    width: auto;
    height: 4rem;
    line-height: 2.5rem;
    margin-right: 3rem;
    margin-top: 1.5rem;
    font-size: 1.1rem;
    padding: .5rem .5rem .5rem 1rem;
    border-bottom: 1px solid #e551b7;
    border-top: .5px solid #e8e8e8;
    backface-visibility: hidden;
    color: #e551b7; }
    @media (max-width: 37.5em) {
      .form .btn-simple:link, .form .btn-simple:visited {
        flex: 0 0 45%;
        width: auto;
        height: 9rem;
        line-height: 7.5rem;
        margin-left: 6%;
        margin-top: 3.5rem;
        font-size: 2.5rem;
        padding: .5rem 2.5rem .5rem 1rem; } }
  .form .btn-simple:hover {
    border-bottom: 0.5rem solid #e551b7;
    border-top: 1px solid #e8e8e8;
    transform: translateY(-0.5rem); }
  .form .btn-simple:active {
    transform: translateY(0);
    border-bottom: 0.1px solid #e551b7;
    border-top: .5px solid #e8e8e8; }
  .form-back {
    background-image: linear-gradient(77deg, rgba(0, 107, 153, 0.6) 0%, rgba(0, 107, 153, 0.6) 50%, rgba(255, 255, 255, 0.91) 50%), url("/img/Group 1.jpg");
    background-size: cover;
    filter: drop-shadow(0px 15px 26px rgba(0, 0, 0, 0.25));
    transform: translate(-50%, -50%) rotateY(180deg); }
    @media (max-width: 37.5em) {
      .form-back {
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.6) 100%), url("/img/Group 1.jpg");
        background-size: cover;
        box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.6); } }
    .form-back:target {
      transform: translate(-50%, -50%) rotateY(0deg); }
  .form__header-back {
    position: relative;
    width: 39%;
    margin-top: 12%;
    font-size: 3rem;
    line-height: 6rem;
    display: inline-block;
    color: #202020;
    text-shadow: 0px 1px 4px rgba(32, 32, 32, 0.24);
    margin-bottom: 4rem; }
    @media (max-width: 37.5em) {
      .form__header-back {
        text-align: right;
        font-size: 5.3rem;
        padding-right: 6rem;
        line-height: 14rem;
        flex: 0 0 50%;
        width: auto; } }
  .form__group-back {
    width: 40%;
    position: relative;
    margin-bottom: 1rem; }
    @media (max-width: 37.5em) {
      .form__group-back {
        width: 90%;
        margin: 3rem auto;
        height: 10rem; } }
  .form__checkbox-group-back {
    padding-right: 1rem;
    margin-bottom: 2rem; }
  .form__checkbox-input-back {
    padding-right: 2.5rem; }
  .form__checkbox-label-back {
    position: relative;
    padding-right: 2.5rem; }
    @media (max-width: 37.5em) {
      .form__checkbox-label-back {
        padding-right: 11.5rem;
        font-size: 4rem; } }
  .form__checkbox-button-back {
    cursor: pointer;
    position: absolute;
    right: -1rem;
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 5px;
    border: 3.5px solid #006b99; }
    @media (max-width: 37.5em) {
      .form__checkbox-button-back {
        right: 5rem;
        top: 1.1rem;
        width: 5rem;
        height: 5rem; } }
  .form__checkbox-check-back {
    color: #006b99;
    font-size: 2.5rem;
    position: absolute;
    top: -.5rem;
    right: -.5rem;
    transition: all .3s;
    opacity: 0;
    visibility: hidden; }
    @media (max-width: 37.5em) {
      .form__checkbox-check-back {
        font-size: 4rem; } }
  .form__checkbox-input-back:checked ~ .form__checkbox-label-back .form__checkbox-button-back .form__checkbox-check-back {
    opacity: 1;
    visibility: visible; }
  .form .btn-simple-back:link, .form .btn-simple-back:visited {
    position: absolute;
    left: 0;
    width: 12rem;
    height: 4rem;
    line-height: 2.5rem;
    margin-right: 3rem;
    margin-top: 1.5rem;
    font-size: 1rem;
    padding: .5rem .5rem .5rem 1rem;
    float: left;
    color: #006b99;
    border-bottom: 3px solid #006b99;
    border-top: 3px solid #e8e8e8;
    margin-left: .5rem; }
    @media (max-width: 37.5em) {
      .form .btn-simple-back:link, .form .btn-simple-back:visited {
        flex: 0 0 45%;
        width: auto;
        height: 9rem;
        line-height: 7.5rem;
        margin-left: 6%;
        margin-top: 3.5rem;
        font-size: 2.5rem;
        padding: .5rem 2.5rem .5rem 1rem; } }
  .form .btn-simple-back:hover {
    border-bottom: 0.5rem solid #006b99;
    border-top: 1.5px solid #e8e8e8;
    transform: translateY(-0.5rem); }
  .form .btn-simple-back:active {
    transform: translateY(0);
    border-bottom: 3px solid #006b99;
    border-top: 4px solid #e8e8e8; }
  .form .small-btn-back {
    margin: .7rem;
    color: #4f9ed0;
    transform: translateY(0); }
    @media (max-width: 37.5em) {
      .form .small-btn-back {
        font-size: 3.5rem;
        line-height: 10rem;
        margin: -3.3rem; } }
    .form .small-btn-back:hover {
      transform: translateY(-0.5rem);
      color: #006b99; }
    .form .small-btn-back:active {
      transform: translateY(0); }
  .form-back:target ~ .form-front {
    transform: translate(-50%, -50%) rotateY(-180deg); }
  .form-front:target ~ .form-back {
    transform: translate(-50%, -50%) rotateY(180deg); }

.container-forgot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 43%;
  height: 49%;
  background-color: #ffffff;
  border-radius: 1.3rem; }
  @media (max-width: 37.5em) {
    .container-forgot {
      width: 86%; } }

.center {
  display: block;
  width: 100%;
  height: 100%;
  position: relative; }

.form-forgot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: all .8s ease;
  border-radius: 1.3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4); }
  @media (max-width: 37.5em) {
    .form-forgot {
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.6) 100%), url("/img/slaid4-2-1 1.jpg");
      background-size: cover;
      box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.6); } }
  .form-forgot__container {
    position: absolute;
    top: 33%;
    right: 3.5rem;
    width: 98%;
    transform: translate(0%, -50%); }
  .form-forgot__group {
    width: 80%;
    position: relative;
    margin-bottom: 1rem; }
    @media (max-width: 37.5em) {
      .form-forgot__group {
        width: 96%; } }

.headingContainer {
  max-height: 8rem;
  height: 8rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline; }
  .headingContainer__line {
    border-bottom: 0.3rem solid rgba(var(--black-main-color), 1);
    flex: 1; }
  .headingContainer__txt {
    height: 100%;
    font-size: 2.6rem;
    font-weight: bold;
    padding: 0 1rem; }
    @media (max-width: 37.5em) {
      .headingContainer__txt {
        font-size: 3.5rem; } }

.products {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow-x: hidden;
  padding: 1.5rem 0; }
  @media (hover: hover) {
    .products__item:hover .products__item-img {
      transform: scale(1.12); } }
  .products__item {
    transition: all .2s;
    font-size: var(--nav-font-light);
    position: relative;
    flex: 0 0 25rem;
    height: 31rem;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    padding: 2rem;
    border-radius: 3rem;
    box-shadow: 0 2px 5px rgba(var(--black-main-color), 0.4);
    margin: 1.5rem 1rem; }
    @media (max-width: 37.5em) {
      .products__item {
        flex: 0 0 36rem;
        min-height: 43rem;
        height: auto;
        font-size: 2rem;
        flex-wrap: wrap; } }
    .products__item a:first-child {
      grid-row: 1/-1;
      grid-column: 1/3; }
    .products__item-img {
      transition: all 1s;
      flex: 1;
      width: 100%; }
    .products__item-name {
      transition: all .2s; }
      @media (max-width: 37.5em) {
        .products__item-name {
          grid-row: 1/2;
          grid-column: 3/-1; } }
      .products__item-name:hover {
        color: rgba(var(--primary-color), 1); }
    .products__item-priceContainer {
      width: 100%;
      display: flex;
      flex-direction: row; }
      @media (max-width: 37.5em) {
        .products__item-priceContainer {
          grid-row: 2/3;
          grid-column: 3/-1; } }
    .products__item-price {
      width: 50%;
      display: inline-block;
      text-align: right;
      padding-top: .5rem;
      font-size: 2rem; }
      @media (max-width: 37.5em) {
        .products__item-price {
          font-size: 2.7rem; } }
    .products__item-oldPrice {
      position: relative;
      display: flex;
      align-items: center;
      width: 20%;
      margin-right: auto;
      text-align: center;
      padding-top: .5rem;
      font-size: 1.5rem; }
      @media (max-width: 37.5em) {
        .products__item-oldPrice {
          font-size: 2.5rem; } }
      .products__item-oldPrice::after {
        content: '';
        width: 95%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        border-bottom: .2rem solid black; }
    .products__item-discount {
      border-radius: 50%;
      width: 11%;
      background-color: red;
      color: white;
      height: 2rem;
      display: flex;
      align-self: center;
      align-items: center;
      text-align: center;
      padding-top: .5rem;
      margin-right: .4rem;
      font-size: 1.2rem; }
      @media (max-width: 37.5em) {
        .products__item-discount {
          width: 4rem;
          height: 4rem;
          font-size: 2rem; } }
    .products__item-link {
      max-width: 15rem;
      text-align: center;
      padding-top: 1rem;
      border-bottom: 2px solid transparent;
      transition: all .2s;
      position: absolute;
      bottom: 1.5rem; }
      .products__item-link:hover {
        color: rgba(var(--primary-color), 1);
        border-bottom: 2px solid rgba(var(--primary-color), 1); }
      @media (max-width: 37.5em) {
        .products__item-link {
          position: unset;
          bottom: unset;
          display: block;
          flex: 1;
          order: 1; } }

.row {
  max-width: 114rem;
  margin: 0 auto; }
  .row:not(:last-child) {
    margin-bottom: 8rem; }
  .row::after {
    content: '';
    display: table;
    clear: both; }
  .row [class^="col-"] {
    float: left; }
    .row [class^="col-"]:not(:last-child) {
      margin-right: 6rem; }
  .row .col-1-of-2 {
    width: calc( (100% - 6rem) / 2); }
  .row .col-1-of-3 {
    width: calc( (100% - 2 * 6rem) / 3); }
  .row .col-2-of-3 {
    width: calc(2* ((100% - 2 * 6rem) / 3) + 6rem); }
  .row .col-1-of-4 {
    width: calc( (100% - 3 * 6rem) / 4); }
  .row .col-2-of-4 {
    width: calc(2* ((100% - 3 * 6rem) / 4) +  6rem); }
  .row .col-3-of-4 {
    width: calc(3* ((100% - 3 * 6rem) / 4) + 2* 6rem); }

.navbar-phone {
  display: none; }

.navbar-phone__slider {
  display: none; }

.phone__searchBar {
  display: none; }

@media (max-width: 37.5em) {
  .phone__searchBar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    flex-wrap: wrap;
    margin: 0 auto 2rem 0; }
    .phone__searchBar--adv {
      flex: 0 0 100%;
      height: 10rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 2rem 2rem;
      background: rgba(var(--primary-color), 0.35); }
    .phone__searchBar--advContainer {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center; }
    .phone__searchBar--advLink {
      text-shadow: 0 2px 3px rgba(0, 0, 0, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3.1rem;
      color: var(--white-color); } }
    @media only screen and (max-width: 37.5em) and (max-width: 25em) {
      .phone__searchBar--advLink {
        font-size: 2.5rem; } }

@media (max-width: 37.5em) {
      .phone__searchBar--advLink:nth-child(2) {
        margin: 0 6rem; }
    .phone__searchBar--advImg {
      width: 28rem; }
    .phone__searchBar--search {
      flex: 0 0 100%;
      padding: 2rem 4rem;
      height: 11rem; }
    .phone__searchBar--searchContainer {
      flex: 0 0 60%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      position: relative;
      color: rgba(var(--black-main-color), 1);
      height: 100%; }
    .phone__searchBar--searchInput {
      border-radius: 3rem;
      width: 71%;
      padding: 3.1rem 9rem;
      font-size: 3rem;
      border: none;
      box-shadow: 0 1px 3px rgba(var(--black-main-color), 0.4);
      transition: all .2s; }
    .phone__searchBar--searchInput:focus {
      outline: none;
      box-shadow: inset 0 1px 3px rgba(var(--black-main-color), 0.4); }
    .phone__searchBar--searchIcon {
      position: absolute;
      font-size: 4rem;
      right: 18%;
      top: 2rem;
      visibility: hidden;
      color: currentColor;
      opacity: 0;
      transition: all .2s; }
    .phone__searchBar--searchInput:placeholder-shown ~ .phone__searchBar--searchIcon {
      opacity: 1 !important;
      visibility: visible !important; }
  .navbar {
    display: none; }
  .navbar-phone {
    display: block;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    opacity: .9;
    background: rgba(var(--gray-blur-color), 1);
    box-shadow: 0 -5px 1rem #ffffffa3;
    -webkit-backdrop-filter: blur(1rem);
    height: var(--phone-menu-height);
    z-index: 999; }
    @supports (backdrop-filter: blur(3rem)) {
      .navbar-phone {
        background: rgba(var(--gray-blur-color), 0.8);
        backdrop-filter: blur(3rem); } }
    .navbar-phone__nav {
      display: flex;
      height: 100%;
      width: inherit;
      flex-direction: row; }
    .navbar-phone__item {
      width: 100%;
      height: inherit;
      font-size: 2rem;
      line-height: 9rem;
      display: inline-block;
      align-items: center;
      text-align: center;
      text-shadow: 0 1px 4px rgba(32, 32, 32, 0.24);
      transition: all .2s; }
      .navbar-phone__item:last-child {
        transform: translateX(1.5rem); }
      .navbar-phone__item:hover {
        transform: scale(1.03);
        font-family: Vazir-bold; }
        .navbar-phone__item:hover:last-child {
          transform: scale(1.03) translateX(1.5rem); }
      .navbar-phone__item:hover(:last-child) {
        transform: scale(1.03) translateX(0rem);
        font-family: Vazir-bold; }
    .navbar-phone__link {
      color: rgba(var(--black-main-color), 0.8);
      text-shadow: 0 0.08rem 4px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      font-size: 3rem;
      height: 100%;
      cursor: pointer;
      filter: drop-shadow(0px 4px 9px rgba(var(--black-main-color), 0.4)); }
      .navbar-phone__link:active {
        transform: translateY(0.1rem) scale(1.05); }
    .navbar-phone__link:active .navbar-phone__icon {
      color: rgba(var(--primary-color), 1); }
    .navbar-phone__icon:hover {
      color: rgba(var(--primary-color), 1); }
    .navbar-phone__icon:active {
      color: rgba(var(--primary-color), 1); }
    .navbar-phone__icon {
      margin-top: 3rem;
      font-size: 6rem;
      transition: all .3s; }
    .navbar-phone__slider {
      max-height: 95vh;
      height: 100vh;
      width: 70%;
      box-shadow: -5px 0px 1rem rgba(var(--black-main-color), 0.2);
      background: rgba(var(--gray-blur-color), 1);
      transition: all 0.8s cubic-bezier(0.49, 0.94, 0.42, 0.79);
      position: fixed;
      right: 0;
      top: 0;
      bottom: var(--phone-menu-height);
      z-index: 998;
      flex-direction: column;
      justify-content: flex-start;
      align-content: flex-start;
      display: flex;
      padding-top: 8rem;
      transform: translateX(100%);
      visibility: hidden; }
      @supports (backdrop-filter: blur(1rem)) {
        .navbar-phone__slider {
          background: rgba(var(--gray-blur-color), 0.85);
          backdrop-filter: blur(3rem); } }
      .navbar-phone__slider--icon {
        margin-right: 4rem;
        transition: all .3s; }
      .navbar-phone__slider--close {
        position: absolute;
        top: 0;
        width: 100%;
        height: 5rem;
        right: 0;
        background: rgba(var(--primary-light-color), 1);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; }
      .navbar-phone__slider--item {
        min-height: 10rem;
        text-align: right;
        padding: 5rem 7rem;
        font-size: 4rem;
        text-shadow: 0 3px 5px #0000003d;
        border-bottom: 1px solid #0000003d; }
      .navbar-phone__slider--link {
        cursor: pointer;
        font-weight: bold;
        font-size: 3.1rem; }
        .navbar-phone__slider--link:active {
          color: rgba(var(--primary-color), 1); }
    .navbar-phone__secondSlider {
      background: white;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      box-shadow: inset 0 0.5rem 1rem #00000070;
      padding: 3rem 7rem;
      display: none; }
      .navbar-phone__secondSlider--item {
        text-align: right;
        font-size: 3.2rem; }
        .navbar-phone__secondSlider--item:not(:last-child) {
          margin-bottom: 2.5rem; }
      .navbar-phone__secondSlider--link {
        cursor: pointer;
        color: rgba(var(--black-main-color), 8); }
        .navbar-phone__secondSlider--link:active {
          color: rgba(var(--primary-color), 0.7); } }

#backgroundBlur {
  position: fixed;
  top: var(--menu-height);
  left: 0;
  display: inline;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  backdrop-filter: blur(10px);
  transition: all .8s; }
  @media (max-width: 37.5em) {
    #backgroundBlur {
      top: 0; } }

.navbar {
  width: 100vw;
  height: var(--menu-height);
  position: fixed;
  top: 0;
  right: 0;
  font-size: var(--nav-font-light);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  background-color: var(--backgroundBody-color);
  transition: all .6s;
  z-index: 100; }
  @supports (backdrop-filter: blur(10px)) {
    .navbar {
      background-color: rgba(var(--gray-blur-color), 0.7);
      backdrop-filter: blur(10px); } }
  .navbar__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    height: 7.5rem; }
    .navbar__top__left {
      display: flex;
      flex-direction: row-reverse;
      align-items: center; }
    .navbar__top__bg {
      position: fixed;
      top: var(--menu-height);
      left: 0;
      display: inline;
      height: 100vh;
      width: 100vw;
      z-index: -200;
      backdrop-filter: blur(10px);
      transition: all .8s; }
      @media (max-width: 37.5em) {
        .navbar__top__bg {
          top: 0; } }
    .navbar__top__user {
      flex: 0 0 50%;
      font-size: var(--nav-font-md);
      padding-left: 4.5rem;
      cursor: pointer;
      color: rgba(var(--black-main-color), 1);
      transition: all .3s; }
      .navbar__top__user-link {
        color: rgba(var(--black-main-color), 1); }
        .navbar__top__user-link:hover {
          color: rgba(var(--primary-color), 1);
          transition: all .3s; }
    .navbar__top__shop {
      flex: 0 0 50%;
      font-size: var(--nav-font-md);
      padding-left: 2rem;
      cursor: pointer;
      transition: all .3s;
      color: rgba(var(--black-main-color), 1); }
      .navbar__top__shop:hover {
        color: rgba(var(--primary-color), 1); }
      .navbar__top__shop-link {
        color: rgba(var(--black-main-color), 1); }
        .navbar__top__shop-link:hover {
          color: rgba(var(--primary-color), 1);
          transition: all .3s; }
    .navbar__top__middle {
      flex: 0 0 60%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      position: relative;
      color: rgba(var(--black-main-color), 1);
      height: 4rem; }
    .navbar__top__search {
      width: 80%;
      position: absolute;
      right: 9rem;
      font-family: inherit;
      padding: .8rem 4rem;
      border: none;
      border-radius: .5rem;
      background-color: var(--background-gray-light-color);
      transition: all .3s; }
      .navbar__top__search:focus {
        outline: none;
        background-color: var(--white-color);
        box-shadow: inset 0 0.2rem 0.3rem rgba(var(--black-main-color), 0.3); }
    .navbar__top__search-icon {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      right: 10rem;
      top: .9rem; }
    .navbar__top__search:placeholder-shown + .navbar__top__search-icon {
      opacity: .6;
      visibility: visible; }
    .navbar__top__logo {
      width: 14rem;
      margin: .6rem 1rem; }
  .navbar__bottom {
    height: 35%;
    padding-bottom: 1rem;
    font-size: var(--nav-font-md); }
    .navbar__bottom__container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: row-reverse;
      list-style: none; }
    .navbar__bottom__item {
      cursor: pointer;
      height: 100%;
      align-self: center;
      display: flex;
      align-items: center;
      transition: all .1s; }
      .navbar__bottom__item:hover {
        border-bottom: 0.2rem solid rgba(var(--primary-color), 1); }
      .navbar__bottom__item:hover > a {
        color: rgba(var(--primary-color), 100%);
        text-shadow: 0 1rem 1rem rgba(var(--primary-color), 0.3); }
    .navbar__bottom__link {
      transition: all .3s;
      color: rgba(var(--black-main-color), 1); }
      .navbar__bottom__link:visited, .navbar__bottom__link:link {
        color: rgba(var(--black-main-color), 1);
        font-weight: bold;
        text-shadow: 0 1px 3px rgba(var(--black-main-color), 0.3); }
      .navbar__bottom__link:active {
        transform: translateY(0.5rem); }
    .navbar__bottom__dropdown {
      position: fixed;
      display: flex;
      opacity: 0;
      visibility: hidden;
      height: auto;
      font-size: var(--nav-font-light);
      transform: scaleY(0);
      transform-origin: top;
      flex-direction: row-reverse;
      justify-content: space-around;
      align-items: flex-start;
      padding-top: 4rem;
      top: var(--menu-height);
      list-style: none;
      left: 0;
      background: linear-gradient(274.62deg, rgba(var(--primary-medium-color), 1) 0%, rgba(var(--primary-light-color), 1) 16.21%, white 99.03%);
      max-height: 90vh;
      min-height: 34rem;
      width: 100vw;
      box-shadow: 0 0.3rem 2rem rgba(var(--black-main-color), 0.2);
      transition: all .5s;
      z-index: -1;
      backface-visibility: hidden; }
      @supports (backdrop-filter: blur(10px)) {
        .navbar__bottom__dropdown {
          background: rgba(255, 255, 255, 0.8);
          backdrop-filter: blur(2rem); } }
    .navbar__bottom__dropdown__bg {
      position: fixed;
      top: var(--menu-height);
      left: 0;
      opacity: 0;
      visibility: hidden;
      display: inline;
      height: 100vh;
      width: 100vw;
      z-index: -200;
      backdrop-filter: blur(10px);
      transition: all .8s; }
    .navbar__bottom__dropdown-item {
      text-align: center;
      opacity: 0;
      transition: all 1s; }
    .navbar__bottom__dropdown-link {
      color: rgba(var(--black-main-color), 0.5);
      font-weight: bolder;
      text-shadow: 0 0.2rem 0.5rem rgba(var(--black-main-color), 0.2);
      font-size: var(--nav-font-md); }
    .navbar__bottom__dropdown__background-img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 20rem;
      height: 19rem;
      z-index: -100; }
    .navbar__bottom__secondDropdown {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top: 1.5rem; }
    .navbar__bottom__secondDropdown__item:not(:last-child) {
      padding-bottom: 1.2rem; }
    .navbar__bottom__secondDropdown__link {
      transition: all .2s;
      font-weight: bold; }
      .navbar__bottom__secondDropdown__link:hover {
        color: rgba(var(--primary-color), 0.7); }
    .navbar__bottom__item-dropdown:hover > .navbar__bottom__dropdown {
      opacity: 1;
      visibility: visible;
      transform: scaleY(1); }
    .navbar__bottom__item-dropdown:hover > .navbar__bottom__dropdown .navbar__bottom__dropdown-item {
      opacity: 1; }

footer {
  display: block;
  margin-top: 5rem;
  width: 100%;
  height: auto; }
  @media (max-width: 37.5em) {
    footer {
      width: 100%;
      height: auto;
      position: relative;
      bottom: var(--phone-menu-height);
      margin-top: 20rem; } }

.footer {
  position: relative;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  padding-bottom: 4rem;
  margin-top: -4rem; }
  @media (max-width: 37.5em) {
    .footer {
      display: grid;
      grid-template-rows: 1fr 2fr;
      grid-template-columns: repeat(2, 1fr);
      margin-right: 4%;
      grid-column-gap: 4%;
      grid-row-gap: 11%;
      margin-bottom: 10%; } }
  .footer__contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 0 0 23%;
    font-size: var(--nav-font-light); }
    @media (max-width: 37.5em) {
      .footer__contact {
        order: 1; } }
  @media (max-width: 37.5em) {
    .footer__contact h4 {
      width: 80%;
      text-align: center; } }
  .footer__header {
    font-weight: bold;
    display: inline;
    width: max-content;
    font-size: var(--nav-font-md);
    border-bottom: 2px solid currentColor;
    padding-bottom: 1rem;
    margin-bottom: var(--gap-small); }
    @media (max-width: 37.5em) {
      .footer__header {
        font-size: 3.1rem; } }
  .footer__txt {
    margin-bottom: var(--gap-small);
    font-size: var(--nav-font-light); }
    @media (max-width: 37.5em) {
      .footer__txt {
        font-size: 2.5rem;
        padding: 0 3%;
        line-height: 5.5rem; } }
  .footer__icons {
    display: flex;
    flex-direction: row;
    justify-content: right; }
    @media (max-width: 37.5em) {
      .footer__icons {
        justify-content: space-around; } }
    .footer__icons-link {
      margin-left: 1.7rem;
      font-size: var(--nav-font-light); }
    .footer__icons-img {
      max-height: 2.5rem;
      max-width: 2.5rem; }
      @media (max-width: 37.5em) {
        .footer__icons-img {
          max-height: 4.5rem;
          max-width: 4.5rem; } }
  .footer__links {
    flex: 0 0 23%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    .footer__links-link {
      font-size: var(--nav-font-light);
      margin-bottom: 1rem;
      transition: all .2s; }
      @media (max-width: 37.5em) {
        .footer__links-link {
          font-size: 2.5rem;
          padding: 0 3%; } }
      .footer__links-link:hover {
        color: rgba(var(--primary-color), 1); }
  .footer__manage {
    flex: 0 0 23%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-right: 8rem; }
    @media (max-width: 37.5em) {
      .footer__manage {
        padding-right: 3rem; } }
    .footer__manage-link {
      font-size: var(--nav-font-light);
      margin-bottom: 1rem;
      transition: all .2s; }
      @media (max-width: 37.5em) {
        .footer__manage-link {
          font-size: 2.5rem;
          padding: 0 3%; } }
      .footer__manage-link:hover {
        color: rgba(var(--primary-color), 1); }
  .footer__aboutUs {
    flex: 0 0 25%;
    padding-right: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: var(--nav-font-light); }
    @media (max-width: 37.5em) {
      .footer__aboutUs {
        padding-right: 0; } }

.socket {
  box-shadow: 0 0.4rem 2rem rgba(var(--black-main-color), 0.3);
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  background-color: rgba(var(--background-socket), 1); }
  .socket__license {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    padding-bottom: .6rem; }
  .socket__item-link {
    position: relative;
    font-size: 1.5rem;
    transition: all .2s; }
    @media (max-width: 37.5em) {
      .socket__item-link {
        font-size: 2rem; } }
    .socket__item-link:hover {
      color: rgba(var(--primary-color), 1); }
  .socket__policy {
    flex: 1;
    padding-top: 1rem;
    text-align: center;
    font-family: Arial;
    text-transform: uppercase;
    font-size: 1.2rem; }
    @media (max-width: 37.5em) {
      .socket__policy {
        font-size: 1.5rem; } }

.auth {
  max-width: 100vw;
  height: 55rem;
  position: relative;
  top: 50%;
  transform: translateY(-44%); }
  @media (max-width: 37.5em) {
    .auth {
      max-width: 100vw;
      height: 84%;
      position: relative;
      top: 50%;
      transform: translateY(-59%); } }

.auth-forgot {
  max-width: 100vw;
  height: 55rem;
  position: relative;
  top: 50%;
  transform: translateY(-50%); }
  @media (max-width: 37.5em) {
    .auth-forgot {
      max-width: 100vw;
      height: 152rem;
      position: relative;
      top: 50%;
      transform: translateY(-52%); } }

.sliderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80rem; }
  @media (max-width: 37.5em) {
    .sliderContainer {
      margin-bottom: 6rem;
      height: 70vh; } }

.slider {
  padding: 3rem;
  background-color: var(--backgroundBody-color);
  width: 100vw;
  max-width: 100vw;
  height: 59rem;
  display: flex;
  margin-top: var(--menu-height);
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 3rem; }
  @media (max-width: 37.5em) {
    .slider {
      flex-wrap: wrap;
      margin: 0 auto;
      display: grid;
      padding: 0;
      height: 100%;
      grid-template-rows: repeat(3, 1fr);
      position: relative;
      grid-template-columns: 4fr 1fr; } }
  .slider__slider {
    flex: 0 0 70%;
    max-width: 70%;
    height: 59rem;
    max-height: 100%;
    position: relative;
    transition: all .2s; }
    @media (max-width: 37.5em) {
      .slider__slider {
        grid-row: 1/3;
        grid-column: 1/3;
        width: 100%;
        max-width: unset;
        height: 100%;
        margin: 0 auto;
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        display: flex;
        justify-content: center; } }
    .slider__slider__container {
      width: 97%;
      height: inherit; }
      .slider__slider__container::after {
        content: '';
        display: table;
        clear: both; }
    .slider__slider-item {
      transition: all .2s;
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0;
      visibility: hidden;
      text-align: center;
      max-width: 100%;
      width: 97%;
      height: 100%;
      max-height: 100%;
      overflow: hidden;
      border-radius: 3rem;
      box-shadow: 0 3px 1rem rgba(var(--black-main-color), 0.4); }
      @media (max-width: 37.5em) {
        .slider__slider-item {
          right: unset; } }
    .slider__slider-img {
      max-width: inherit;
      max-height: inherit;
      height: 100%;
      width: 100%;
      object-fit: fill;
      user-select: none; }
    .slider__slider-txt {
      opacity: 0;
      visibility: hidden;
      height: 6rem;
      position: absolute;
      bottom: 0;
      display: flex;
      background-color: rgba(0, 0, 0, 0.44);
      right: 0;
      justify-content: center;
      font-size: 2rem;
      align-items: center;
      width: 100%;
      box-shadow: 0 4px 6px rgba(var(--black-main-color), 0.6);
      transition: all .5s; }
      @supports (backdrop-filter: blur(10px)) {
        .slider__slider-txt {
          background-color: rgba(var(--gray-blur-color), 0.3);
          backdrop-filter: blur(10px); } }
      .slider__slider-txt:hover {
        color: rgba(var(--primary-color), 1);
        font-size: 2.1rem;
        text-shadow: 0 3px 4px rgba(var(--primary-color), 0.3); }
    .slider__slider-item:hover .slider__slider-txt {
      opacity: 1;
      visibility: visible; }
    .slider__slider-icon-img {
      width: 1.5rem;
      filter: drop-shadow(0 1px 5px rgba(var(--black-main-color), 0.5));
      transition: all .2s; }
      @media (max-width: 37.5em) {
        .slider__slider-icon-img {
          width: 3rem; } }
      .slider__slider-icon-img:active {
        transform: translateY(0.3rem);
        filter: drop-shadow(0 1px 5px rgba(var(--black-main-color), 1)); }
    .slider__slider-next {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer; }
      @media (max-width: 37.5em) {
        .slider__slider-next {
          right: 4.5%; } }
    .slider__slider-prev {
      position: absolute;
      left: 4%;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer; }
    .slider__slider-dotPag {
      display: flex;
      flex-direction: row-reverse;
      position: absolute;
      top: 1rem;
      right: 47%;
      transform: translateX(-50%);
      color: white;
      font-size: var(--nav-font-light); }
    .slider__slider-dot {
      cursor: pointer;
      margin-left: 2px; }
  .slider__adv {
    flex: 0 0 29%;
    max-width: 30%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 2rem;
    height: 70rem; }
    @media (max-width: 37.5em) {
      .slider__adv {
        grid-row: 3/4;
        grid-column: 1/-1;
        flex-direction: row;
        max-width: unset;
        width: 97%;
        height: 100%;
        margin: 7% auto 0 auto; } }
    .slider__adv-top {
      flex: 0 0 47%;
      width: 100%;
      overflow: hidden;
      border-radius: 3rem; }
      @media (max-width: 37.5em) {
        .slider__adv-top {
          height: 85%;
          box-shadow: 0 3px 1rem rgba(var(--black-main-color), 0.4); } }
      .slider__adv-top__link {
        width: inherit;
        height: inherit;
        display: block; }
        @media (max-width: 37.5em) {
          .slider__adv-top__link {
            height: 100%; } }
      .slider__adv-top__img {
        width: 100%;
        height: 100%; }
        @media (max-width: 37.5em) {
          .slider__adv-top__img {
            object-fit: cover; } }
    .slider__adv-bottom {
      flex: 0 0 47%;
      overflow: hidden;
      border-radius: 3rem; }
      @media (max-width: 37.5em) {
        .slider__adv-bottom {
          height: 85%;
          box-shadow: 0 3px 1rem rgba(var(--black-main-color), 0.4); } }
      .slider__adv-bottom__link {
        width: inherit;
        height: inherit;
        display: block; }
        @media (max-width: 37.5em) {
          .slider__adv-bottom__link {
            height: 100%; } }
      .slider__adv-bottom__img {
        width: 100%;
        height: 100%; }

.discount {
  display: block;
  padding: 3rem;
  width: 100%;
  height: auto;
  position: relative; }
  .discount__next {
    position: absolute;
    right: 1rem;
    top: 50%;
    padding: 3rem 1rem;
    border-radius: 1rem 0 0 1rem;
    transform: translateY(-50%);
    cursor: pointer;
    filter: drop-shadow(0 4px 1rem rgba(var(--black-main-color), 0.8));
    transition: all .2s; }
    @supports (backdrop-filter: blur(10px)) {
      .discount__next {
        background-color: rgba(var(--gray-blur-color), 0.05);
        backdrop-filter: blur(10px); } }
    .discount__next:active {
      filter: drop-shadow(0 2px 0.5rem rgba(var(--black-main-color), 0.9));
      transform: translateY(-45%); }
    .discount__next-img {
      width: 2rem; }
      @media (max-width: 37.5em) {
        .discount__next-img {
          width: 3rem; } }
  .discount__prev {
    position: absolute;
    left: 1%;
    top: 50%;
    padding: 3rem 1rem;
    border-radius: 0 1rem 1rem 0;
    transform: translateY(-50%);
    cursor: pointer;
    filter: drop-shadow(0 4px 1rem rgba(var(--black-main-color), 0.6));
    transition: all .2s; }
    @supports (backdrop-filter: blur(10px)) {
      .discount__prev {
        background-color: rgba(var(--gray-blur-color), 0.05);
        backdrop-filter: blur(10px); } }
    .discount__prev:active {
      filter: drop-shadow(0 2px 0.5rem rgba(var(--black-main-color), 0.9));
      transform: translateY(-45%); }
    .discount__prev-img {
      width: 2rem; }
      @media (max-width: 37.5em) {
        .discount__prev-img {
          width: 3rem; } }

.category__container-men {
  background-image: linear-gradient(to right, rgba(var(--primary-light-color), 0.6), rgba(var(--primary-light-color), 0.6)), url("/img/men-category.jpg") !important;
  overflow: hidden;
  background-blend-mode: lighten;
  background-size: 100% 100%;
  min-height: 39rem;
  height: auto;
  width: 100vw;
  background-attachment: fixed;
  position: relative; }

.category {
  display: block;
  width: 100%;
  height: auto;
  position: relative; }
  .category__container {
    background-image: linear-gradient(to right, rgba(var(--primary-light-color), 0.6), rgba(var(--primary-light-color), 0.6)), url("/img/category-background.jpg");
    background-blend-mode: lighten;
    background-size: 100% 100%;
    min-height: 39rem;
    height: auto;
    overflow: hidden;
    width: 100vw;
    background-attachment: fixed;
    position: relative; }
  .category__next {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(50%);
    cursor: pointer;
    filter: drop-shadow(0 4px 1rem rgba(var(--black-main-color), 0.8));
    transition: all .2s; }
    .category__next:active {
      transform: translateY(159%); }
      @media (max-width: 37.5em) {
        .category__next:active {
          transform: translateY(55%); } }
    .category__next-img {
      width: 2rem; }
      @media (max-width: 37.5em) {
        .category__next-img {
          width: 3rem; } }
  .category__prev {
    position: absolute;
    left: 1%;
    top: 50%;
    transform: translateY(50%);
    cursor: pointer;
    filter: drop-shadow(0 4px 1rem rgba(var(--black-main-color), 0.6));
    transition: all .2s; }
    .category__prev:active {
      transform: translateY(159%); }
      @media (max-width: 37.5em) {
        .category__prev:active {
          transform: translateY(55%); } }
    .category__prev-img {
      width: 2rem; }
      @media (max-width: 37.5em) {
        .category__prev-img {
          width: 3rem; } }
  .category__item {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .category__item-about {
      opacity: 0;
      border-radius: 20%;
      backdrop-filter: blur(3rem);
      visibility: hidden;
      flex: 0 0 30%;
      font-size: var(--nav-font-md);
      background: rgba(var(--primary-light-color), 0.3);
      height: 34rem;
      max-height: 38rem;
      padding: 5rem;
      margin-right: 6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: all .5s ease-out; }
    .category__item-heading {
      padding-bottom: var(--gap-small);
      transition: all .5s ease-out; }
    .category__item-txt {
      max-height: 100%;
      overflow: hidden;
      text-overflow: ellipsis; }
    .category__item-product {
      margin: auto;
      height: 38rem;
      display: flex;
      align-items: center;
      position: relative;
      transition: all .5s ease-out; }
    .category__item-img {
      height: 16rem;
      width: 15rem;
      transition: all .5s ease-out; }

.product {
  position: relative;
  width: 100vw;
  height: 60rem;
  margin-top: calc(var(--menu-height) + 7.5rem);
  background: rgba(var(--primary-light-color), 0.23);
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible; }
  @media (max-width: 37.5em) {
    .product {
      margin-top: 10%;
      height: auto;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;
      grid-row-gap: 8rem; } }
  .product__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: -5%;
    right: 2%;
    width: 48%;
    flex: 0 0 50%;
    height: 63rem;
    border-radius: 3.5rem;
    box-shadow: -2px 9px 16px rgba(var(--black-main-color), 0.25);
    background: var(--white-color);
    padding: 5rem; }
    @media (max-width: 75em) {
      .product__details {
        width: 45%; } }
    @media (max-width: 37.5em) {
      .product__details {
        position: relative;
        width: 97%;
        right: unset;
        top: 1rem;
        grid-row: 2/-1;
        grid-column: 1/-1;
        height: fit-content;
        margin: 0 auto 0rem auto;
        font-size: 3.1rem; } }
    .product__details-discount {
      width: 14rem;
      height: 14rem;
      position: absolute;
      top: -4rem;
      left: -6rem;
      background: rgba(var(--primary-color), 0.23);
      backdrop-filter: blur(10px);
      border-radius: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: var(--font-extra-lg);
      transition: all .2s; }
      @media (max-width: 37.5em) {
        .product__details-discount {
          left: 50%;
          transform: translateX(-50%);
          font-size: 5rem;
          width: 31%;
          height: 10rem; } }
      .product__details-discount > h3 {
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(var(--black-main-color), 0.35); }
    .product__details-cat {
      margin-bottom: .5rem; }
      .product__details-cat:link, .product__details-cat:visited {
        color: rgba(var(--primary-light-color), 1); }
      .product__details-cat:hover {
        color: rgba(var(--primary-color), 1); }
    .product__details-stars {
      align-self: flex-end;
      display: flex;
      flex-direction: row-reverse;
      padding-left: 1.5rem;
      margin-bottom: 1rem; }
      .product__details-stars span {
        color: #FFCB12; }
    .product__details-line {
      margin-bottom: 2.5rem;
      width: 70%;
      align-self: center;
      position: relative; }
      .product__details-line::after {
        content: '';
        position: absolute;
        width: 100%;
        border-bottom: 2px solid rgba(var(--gray-md-color), 0.15); }
    .product__details-feature {
      color: rgba(var(--gray-md-color), 0.8); }
    .product__details-featureTxt {
      font-size: var(--nav-font-md);
      margin-bottom: 4.5rem; }
      @media (max-width: 37.5em) {
        .product__details-featureTxt {
          font-size: 3rem; } }
    .product__details-price {
      align-self: flex-end;
      font-size: var(--font-extra-lg);
      display: flex;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 37.5em) {
        .product__details-price {
          font-size: 4.3rem;
          padding-bottom: 6rem; } }
    .product__details-oldPrice {
      font-size: var(--nav-font-lg);
      margin-top: 1rem;
      position: relative; }
      @media (max-width: 37.5em) {
        .product__details-oldPrice {
          font-size: 3.5rem; } }
      .product__details-oldPrice::after {
        content: '';
        width: 95%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        border-bottom: 2px solid red; }
    .product__details-buyBtn {
      transition: all .2s; }
      .product__details-buyBtn:visited, .product__details-buyBtn:link {
        width: 30rem;
        position: absolute;
        bottom: -3.5rem;
        height: 7rem;
        align-self: center;
        display: flex;
        background: rgba(var(--primary-color), 0.23);
        backdrop-filter: blur(2rem);
        border-radius: 5rem;
        justify-content: center;
        align-items: center;
        font-size: var(--nav-font-lg);
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(var(--black-main-color), 0.35); }
        @media (max-width: 37.5em) {
          .product__details-buyBtn:visited, .product__details-buyBtn:link {
            font-size: 4.3rem;
            width: 65%;
            height: 11rem;
            bottom: -7rem; } }
      .product__details-buyBtn:hover {
        background: rgba(var(--primary-color), 0.5); }
      .product__details-buyBtn:active {
        text-shadow: 0px 1px 2px rgba(var(--black-main-color), 0.4); }
  .product__show {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media (max-width: 37.5em) {
      .product__show {
        flex-direction: row;
        width: 100%;
        height: auto;
        align-items: center;
        flex: 1;
        justify-content: space-around; } }
    .product__show-imgContainer {
      margin: 2rem 0;
      flex: 0 0 40rem;
      overflow: hidden;
      width: 52rem;
      box-shadow: -2px 4px 4px rgba(var(--black-main-color), 0.25);
      border-radius: 3.5rem; }
      @media (max-width: 75em) {
        .product__show-imgContainer {
          width: 45rem; } }
      @media (max-width: 56.25em) {
        .product__show-imgContainer {
          width: 40rem; } }
      @media (max-width: 37.5em) {
        .product__show-imgContainer {
          flex: 0 0 70%;
          height: 61rem; } }
    .product__show-img {
      height: 40rem;
      width: 52rem;
      object-fit: cover; }
      @media (max-width: 75em) {
        .product__show-img {
          width: 100%; } }
      @media (max-width: 37.5em) {
        .product__show-img {
          height: 100%;
          width: 100%; } }
    .product__show-thumbnailContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 52rem; }
      @media (max-width: 75em) {
        .product__show-thumbnailContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 45rem; } }
      @media (max-width: 56.25em) {
        .product__show-thumbnailContainer {
          width: 40rem; } }
      @media (max-width: 37.5em) {
        .product__show-thumbnailContainer {
          flex-direction: column;
          flex: 0 0 10%;
          align-self: center;
          height: 61rem; } }
    .product__show-thumbnail {
      cursor: pointer;
      height: 13rem;
      width: 12rem;
      box-shadow: -3px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3.5rem;
      overflow: hidden;
      transition: all .2s; }
      @media (max-width: 37.5em) {
        .product__show-thumbnail {
          height: 17rem;
          width: 15rem; } }
    .product__show-thumbnailImg {
      height: 13rem;
      width: 12rem;
      object-fit: cover; }
      @media (max-width: 37.5em) {
        .product__show-thumbnailImg {
          width: 100%;
          height: 100%; } }

.review {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  margin-top: 10rem; }
  @media (max-width: 37.5em) {
    .review {
      margin-top: 20%; } }
  .review__header-container {
    z-index: 0;
    width: 92vw;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
    @media (max-width: 37.5em) {
      .review__header-container {
        font-size: 3rem; } }
  .review__header-item {
    outline: none;
    margin-left: 2rem;
    background: var(--white-color);
    box-shadow: -2px -3px 10px rgba(var(--black-main-color), 0.25);
    border-radius: 3rem 3rem 0 0;
    transition: all .2s;
    padding: 1rem 2rem;
    text-shadow: -1px 3px 4px rgba(var(--black-main-color), 0.2); }
    .review__header-item-active {
      background: rgba(var(--primary-light-color), 0.6);
      box-shadow: -2px -2px 4px rgba(var(--black-main-color), 0.25); }
  .review__header-item-active > .review__header-link:visited, .review__header-item-active > .review__header-link:link {
    outline: none;
    color: var(--white-color);
    text-shadow: -1px 2px 3px rgba(var(--black-main-color), 0.3); }
  .review__header-link {
    outline: none;
    transition: all .2s; }
  .review__section {
    z-index: 1;
    width: 97vw;
    height: auto;
    background: var(--white-color);
    border-radius: 3.3rem;
    margin: 0 auto;
    padding: 4rem;
    transition: all .2s; }
    @media (max-width: 37.5em) {
      .review__section {
        font-size: 3.2rem; } }
    .review__section-subject {
      width: 12rem;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid rgba(var(--gray-md-color), 0.5);
      margin-bottom: 1rem;
      transition: all .2s; }
      @media (max-width: 37.5em) {
        .review__section-subject {
          min-width: 31%; } }
    .review__section-txt {
      margin-top: 3rem; }
      @media (max-width: 37.5em) {
        .review__section-txt {
          padding: 5%; } }
    .review__section-container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start; }
    .review__section-sticky {
      position: sticky;
      display: flex;
      width: 37rem;
      top: 14rem;
      right: 100%;
      height: 27rem;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      z-index: 1;
      flex: 1;
      backdrop-filter: blur(20px);
      background: rgba(var(--primary-light-color), 23%);
      border-radius: 3rem; }
      @media (max-width: 37.5em) {
        .review__section-sticky {
          display: none; } }
    .review__section-sticky-details {
      display: flex;
      padding: 0 1rem;
      justify-content: space-between;
      align-items: center; }
    .review__section-sticky-img {
      height: 11rem;
      flex: 0 0 40%;
      margin-left: 1rem;
      border-radius: 2rem; }
    .review__section-sticky-link {
      font-size: var(--nav-font-md);
      transition: all .2s; }
      .review__section-sticky-link:hover {
        color: rgba(var(--primary-color), 1); }
    .review__section-sticky-buy {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-around;
      align-items: center; }
    .review__section-sticky-btn {
      flex: 0 0 50%;
      height: 5.5rem;
      align-items: center;
      display: flex;
      background: rgba(var(--primary-color), 0.6);
      border-radius: 5rem;
      justify-content: center;
      color: #FFFFFF;
      text-shadow: 0 4px 4px rgba(var(--black-main-color), 0.35);
      transition: all .2s; }
      .review__section-sticky-btn:hover {
        background: rgba(var(--primary-color), 0.8); }
    .review__section-feature {
      margin-right: 5rem;
      flex: 0 0 69%; }
      @media (max-width: 75em) {
        .review__section-feature {
          flex: 0  0 55%; } }
      @media (max-width: 37.5em) {
        .review__section-feature {
          flex: 0 0 90%; } }
    .review__section-feature-item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
    .review__section-feature-key {
      min-width: 23rem;
      padding: 2rem; }
      @media (max-width: 37.5em) {
        .review__section-feature-key {
          padding: 5% 0%;
          margin-left: 10%; } }
    .review__section-comment {
      margin-right: 5rem;
      flex: 0 0 69%; }
      @media (max-width: 75em) {
        .review__section-comment {
          flex: 0 0 60%;
          margin-right: 0; } }
    .review__section-comment-item {
      width: 75rem;
      height: auto;
      margin-bottom: 3rem;
      padding-top: 2rem;
      padding-right: 6rem;
      min-height: 16rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url("/img/comment.png");
      position: relative; }
      @media (max-width: 75em) {
        .review__section-comment-item {
          width: 95%; } }
      @media (max-width: 37.5em) {
        .review__section-comment-item {
          min-height: 20%;
          width: 75rem; } }
    .review__section-comment-txt {
      overflow: hidden;
      word-wrap: break-word;
      padding: 1rem; }
    .review__section-comment-footer {
      position: absolute;
      bottom: 2rem;
      left: 4rem;
      display: flex; }
      @media (max-width: 37.5em) {
        .review__section-comment-footer {
          bottom: 8%; } }
    .review__section-comment-date {
      margin-left: 2rem; }
    .review__section-comment-reply {
      border-radius: 3rem;
      padding: 2rem;
      backdrop-filter: blur(2rem);
      width: 70%;
      display: flex;
      margin-right: 4rem;
      background: rgba(var(--primary-color), 23%); }
      @media (max-width: 37.5em) {
        .review__section-comment-reply {
          margin-bottom: 10%; } }
    .review__section-divider {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      margin-bottom: 1rem; }
      .review__section-divider-header {
        margin-left: 2rem;
        font-size: 2rem;
        font-weight: bold;
        padding: 0 1rem; }
        @media (max-width: 37.5em) {
          .review__section-divider-header {
            font-size: 3.5rem; } }
      .review__section-divider-line {
        flex: 1;
        border-bottom: 3px solid rgba(var(--black-main-color), 0.8); }
    @media (max-width: 37.5em) {
      .review__section-addComment-container {
        margin-top: 25%; } }
    .review__section-addComment-form {
      padding: 3rem; }
      @media (max-width: 37.5em) {
        .review__section-addComment-form {
          padding: 3rem 0; } }
    .review__section-addComment-formGroup {
      padding: 1.5rem; }
    .review__section-addComment-input {
      border-radius: 3rem;
      min-width: 70rem;
      max-width: 100%;
      box-shadow: -2px 3px 4px rgba(var(--black-main-color), 0.4);
      padding: 1.5rem 2rem;
      transition: all .2s; }
      .review__section-addComment-input:focus {
        box-shadow: inset -2px 3px 4px rgba(var(--black-main-color), 0.4);
        outline: none; }
    .review__section-addComment-txtArea {
      border-radius: 3rem;
      min-width: 70rem;
      max-width: 100%;
      min-height: 17rem;
      box-shadow: -2px 3px 4px rgba(var(--black-main-color), 0.4);
      padding: 1.5rem 2rem;
      transition: all .2s; }
      @media (max-width: 37.5em) {
        .review__section-addComment-txtArea {
          min-height: 30rem; } }
      .review__section-addComment-txtArea:focus {
        box-shadow: inset -2px 3px 4px rgba(var(--black-main-color), 0.4);
        outline: none; }
    .review__section-addComment-rules {
      font-size: var(--nav-font-md);
      padding-right: 1.5rem; }
      .review__section-addComment-rules-link {
        transition: all .2s;
        color: rgba(var(--primary-light-color), 1); }
        .review__section-addComment-rules-link:hover {
          color: rgba(var(--primary-color), 1); }
    .review__section-addComment-submit {
      height: 5.5rem;
      align-items: center;
      display: flex;
      background: rgba(var(--primary-color), 0.6);
      border-radius: 5rem;
      justify-content: center;
      color: #FFFFFF;
      text-shadow: 0 4px 4px rgba(var(--black-main-color), 0.35);
      transition: all .2s;
      width: 15rem;
      margin: 2rem; }
      @media (max-width: 37.5em) {
        .review__section-addComment-submit {
          width: auto;
          height: 10rem;
          font-size: 4rem; } }
      .review__section-addComment-submit:hover {
        background: rgba(var(--primary-color), 0.8); }

.shop {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  height: auto; }
  @media (max-width: 37.5em) {
    .shop__price {
      font-size: 5.5rem !important; } }
  @media (max-width: 37.5em) {
    .shop__oldPrice {
      font-size: 4rem !important;
      width: unset; } }
  @media (max-width: 37.5em) {
    .shop__discount {
      font-size: 2.5rem !important;
      margin-right: 3rem;
      width: unset;
      padding: .8rem;
      height: unset; } }
  @media (max-width: 37.5em) {
    .shop__name {
      font-size: 3rem !important; } }
  @media (max-width: 37.5em) {
    .shop__link {
      font-size: 3rem;
      grid-row: 3/-1;
      grid-column: 3/-1;
      max-width: unset;
      width: auto;
      margin: 0 auto;
      position: relative;
      bottom: 0;
      text-align: center;
      font-weight: bold; } }
  @media (max-width: 37.5em) and (hover: none) {
    .shop__link {
      color: rgba(var(--primary-color), 1) !important;
      border-bottom: 0.15rem solid rgba(var(--primary-color), 1) !important; } }
  .shop__adv {
    margin-top: calc(var(--menu-height) + 3rem);
    margin-bottom: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    max-width: 100%;
    height: 30rem;
    width: 95vw; }
    @media (max-width: 37.5em) {
      .shop__adv {
        margin-top: 0; } }
    .shop__adv-container {
      max-height: 100%;
      height: 25rem;
      width: 87%; }
      @media (max-width: 56.25em) {
        .shop__adv-container {
          width: 100%; } }
      .shop__adv-container:not(:last-child) {
        margin-left: 5rem; }
    .shop__adv-link {
      width: inherit;
      height: inherit; }
    .shop__adv-img {
      object-fit: cover;
      width: inherit;
      height: inherit;
      border-radius: 3rem;
      box-shadow: -3px 2px 4px rgba(var(--black-main-color), 0.23);
      transition: all 1s; }
      @media (hover: hover) {
        .shop__adv-img:hover {
          transform: scale(1.02); } }
  .shop__breadCrumb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95vw;
    text-align: right;
    margin-bottom: 1rem;
    font-size: var(--nav-font-light);
    padding: 0 2%; }
    .shop__breadCrumb-txt {
      margin-left: .5rem;
      transition: all .2s; }
      .shop__breadCrumb-txt:hover {
        color: rgba(var(--primary-color), 0.7); }
    .shop__breadCrumb-icon {
      font-size: 1rem;
      margin-left: .5rem; }
  .shop__shop {
    width: 95vw;
    min-height: 100vh;
    height: auto;
    border-radius: 3rem;
    overflow: hidden;
    box-shadow: -1px 3px 4px rgba(var(--black-main-color), 0.23);
    background: var(--white-color);
    position: relative;
    transition: all .3s; }
    @media (max-width: 37.5em) {
      .shop__shop-products-item {
        flex: 0 0 95%;
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(4, 1fr); } }
    .shop__shop-filters {
      display: none; }
      @media (max-width: 56.25em) {
        .shop__shop-filters {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%; } }
    .shop__shop-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      padding: 2rem 5rem;
      margin: 3rem 0; }
      @media (max-width: 37.5em) {
        .shop__shop-header {
          font-size: 2.5rem; } }
    .shop__shop-h3 {
      border-bottom: 0.2rem solid rgba(var(--black-main-color), 1);
      position: absolute;
      left: 48%;
      transform: translateX(-50%);
      padding-bottom: .5rem;
      margin: auto; }
      .shop__shop-h3::after {
        content: '';
        position: absolute;
        bottom: -1rem;
        right: 26%;
        width: 50%;
        border-bottom: 0.2rem solid rgba(var(--black-main-color), 1); }
    @media (max-width: 56.25em) {
      .shop__shop-filter {
        display: none; } }
    .shop__shop-filterItem {
      font-size: 1.3rem;
      margin-left: 1.5rem;
      transition: all .3s;
      padding: .3rem .6rem;
      text-shadow: 0 1px 0.3rem rgba(var(--black-main-color), 0.3); }
      .shop__shop-filterItem:hover {
        border-radius: 3rem;
        background: rgba(var(--primary-color), 0.4);
        color: var(--white-color);
        backdrop-filter: blur(1rem); }
    .shop__shop-available {
      display: flex;
      flex-direction: row-reverse;
      align-items: center; }
      .shop__shop-available-items {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center; }
      @media (max-width: 56.25em) {
        .shop__shop-available {
          width: 100%;
          justify-content: space-between; } }
    .shop__shop-availableTxt {
      font-size: var(--nav-font-light); }
      @media (max-width: 37.5em) {
        .shop__shop-availableTxt {
          font-size: 2rem; } }
    .shop__shop-switch {
      position: relative;
      width: 3.5rem;
      height: 2rem;
      display: inline-block;
      box-shadow: inset 1px 0.3rem 0.4rem rgba(var(--black-main-color), 0.4);
      background: white;
      border-radius: 3rem;
      cursor: pointer;
      transition: all 0.8s;
      -moz-transition: all 0.8s;
      -webkit-transition: all 0.8s;
      margin-right: 1rem; }
      @media (max-width: 37.5em) {
        .shop__shop-switch {
          width: 5.5rem;
          height: 3rem; } }
      .shop__shop-switch::after {
        content: "";
        position: absolute;
        left: .2rem;
        top: .2rem;
        height: 1.5rem;
        width: 1.5rem;
        background: #FFF;
        border-radius: 50%;
        box-shadow: 0.1rem 0.3rem 0.4rem rgba(var(--black-main-color), 0.4);
        transition: all .5s; }
        @media (max-width: 37.5em) {
          .shop__shop-switch::after {
            height: 2.5rem;
            width: 2.5rem; } }
    .shop__shop-inputHack {
      display: block;
      opacity: 0; }
    .shop__shop-inputHack:checked + .shop__shop-switch {
      background: rgba(var(--primary-light-color), 1); }
    .shop__shop-inputHack:checked + .shop__shop-switch::after {
      left: auto;
      right: 2px; }
    .shop__shop-products {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly; }
    .shop__shop-paginate {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: auto;
      height: 6rem;
      background: var(--white-color);
      padding: 1rem 2rem;
      box-shadow: -0.2rem 0.2rem 0.3rem rgba(var(--black-main-color), 0.3);
      margin-top: 2rem;
      border-radius: 3rem; }
      @media (max-width: 37.5em) {
        .shop__shop-paginate {
          height: 10rem; } }
    .shop__shop-page {
      padding: 1rem;
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      flex-direction: row;
      color: rgba(var(--black-main-color), 0.8);
      align-items: center;
      text-shadow: 0 3px 3px rgba(var(--black-main-color), 0.3);
      transition: all .3s; }
      @media (max-width: 37.5em) {
        .shop__shop-page {
          width: 6rem;
          font-size: 3.5rem;
          height: 6rem; } }
      .shop__shop-page:hover {
        background: rgba(var(--primary-color), 0.23);
        backdrop-filter: blur(1rem);
        border-radius: 50%;
        color: var(--white-color); }
    .shop__shop-pageNumbers {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 3rem; }
    .shop__shop-pageLink {
      margin-left: 1rem; }
      .shop__shop-pageLink-active {
        background: rgba(var(--primary-color), 0.8);
        backdrop-filter: blur(1rem);
        border-radius: 50%;
        color: var(--white-color); }
        .shop__shop-pageLink-active:hover {
          background: rgba(var(--primary-color), 0.8); }
    .shop__shop-categoryContainer {
      width: 21%;
      position: absolute;
      right: 0;
      top: 0;
      box-shadow: -6px 0 11px #00000033;
      background: var(--white-color);
      backface-visibility: hidden;
      transform: translateX(100%);
      z-index: 1;
      height: 100%;
      transition: all 0.7s cubic-bezier(0.25, 0.7, 0.92, 0.79); }
      @media (max-width: 56.25em) {
        .shop__shop-categoryContainer {
          width: 40%; } }
      @media (max-width: 37.5em) {
        .shop__shop-categoryContainer {
          width: 65%; } }
      @supports (backdrop-filter: blur(10px)) {
        .shop__shop-categoryContainer {
          background: rgba(var(--gray-blur-color), 0.3);
          backdrop-filter: blur(1rem); } }
    .shop__shop-category {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      transition: all .8s;
      width: 95%;
      min-height: 15rem;
      padding: 6% 4%;
      background: rgba(255, 255, 255, 0.95);
      margin: 7% auto;
      position: sticky;
      top: 0;
      border-radius: 3rem;
      box-shadow: 0 2px 5px rgba(var(--black-main-color), 0.4);
      opacity: 0;
      transform: translateX(100%); }
    .shop__shop-categoryIcon {
      margin-left: .5rem;
      transition: all .2s; }
    .shop__shop-categoryHeader {
      cursor: pointer;
      font-size: 1.3rem;
      margin-left: 1.5rem;
      transition: all .3s;
      text-shadow: 0 1px 0.3rem rgba(var(--black-main-color), 0.3);
      border-bottom: .15rem solid transparent;
      padding-bottom: 0.5rem; }
      @media (max-width: 37.5em) {
        .shop__shop-categoryHeader {
          font-size: 2rem; } }
      .shop__shop-categoryHeader:hover {
        color: rgba(var(--primary-color), 1) !important;
        border-bottom: 0.15rem solid rgba(var(--primary-color), 1) !important; }
    .shop__shop-headerCat {
      text-align: center;
      padding-bottom: 6%;
      width: 100%;
      margin-bottom: 6%;
      border-bottom: 1px solid rgba(var(--gray-md-color), 0.9); }
      @media (max-width: 37.5em) {
        .shop__shop-headerCat {
          font-size: 3.5rem; } }
    .shop__shop-ulCat {
      list-style: persian;
      width: 98%;
      padding: 0 11%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: var(--nav-font-md); }
      @media (max-width: 56.25em) {
        .shop__shop-ulCat {
          margin-bottom: 5rem; } }
    .shop__shop-ulCat a:hover {
      color: rgba(var(--primary-color), 1); }
    .shop__shop-itemCat {
      margin-bottom: 8%; }
    .shop__shop-itemCatRadioContainer {
      margin-bottom: 8%;
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      @media (max-width: 37.5em) {
        .shop__shop-itemCatRadioContainer {
          font-size: 3rem; } }
    .shop__shop-itemCatRadio {
      margin-right: 8%;
      -webkit-appearance: auto;
      appearance: auto; }
    .shop__shop-linkCat {
      margin-left: 5%;
      cursor: pointer; }
      @media (max-width: 37.5em) {
        .shop__shop-linkCat {
          font-size: 3rem; } }
    .shop__shop-dropUlCat {
      font-size: var(--nav-font-light);
      display: none; }
    .shop__shop-iconCat {
      transition: all .3s; }
    .shop__shop-dropLinkCat {
      cursor: pointer; }
      @media (max-width: 37.5em) {
        .shop__shop-dropLinkCat {
          font-size: var(--nav-font-md); } }
    .shop__shop-categoryHeader:hover .shop__shop-categoryIcon {
      transform: translateX(0.3rem); }
    .shop__shop-inputCategoryHack:checked ~ .shop__shop-categoryContainer {
      transform: translateX(0);
      visibility: visible; }
    .shop__shop-inputCategoryHack:checked ~ .shop__shop-categoryContainer > .shop__shop-category {
      transform: translateX(0);
      visibility: visible; }
